import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Button from "../components/Button"
import { copies } from "./Hero.module.css"
import { Link } from "gatsby"

const Hero = ({ src, alt }) => {
  return (
    <div style={{ position: "relative" }}>
      <StaticImage
        src="../images/building.jpg"
        alt="banner"
        className="w-screen"
        height={450}
        objectFit="cover"
      />
      <div className={copies}>
        <h1 className="text-4xl">
          <StaticImage
            src="../images/griguol-logo.png"
            alt="logo de la empresa"
            className="w-52 lg:w-72"
            placeholder="blurred"
          />
        </h1>
        <Link to="/about-us">
          <Button className="mt-4">Quienes Somos</Button>
        </Link>
      </div>
    </div>
  )
}

export default Hero
