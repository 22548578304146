import React from "react"

const WhyChoiseUs = () => {
  const items = [
    {
      title: "+10 años trabajando para toda la Argentina",
      content:
        "Con el objetivo de continuar creciendo y acercando nuestros sistemas a más personas, en el año 2011 la com pañía se expandió a nivel nacional satisfaciendo, de manera directa o a través de Distribuidores Exclusivos, las necesidades de muchos argentinos. ",
    },
    {
      title: "+2300 edificios activos solo en la cuidad de Córdoba",
      content: "",
    },
    {
      title: "+69000 usuarios de nuestros sistemas de control de acceso.",
      content: "",
    },
  ]
  return (
    <div className="container mx-auto px-4 my-8 text-center max-w-5xl">
      <div className="text-lg lg:text-3xl text-primary mb-4">
        RAZONES DE POR QUÉ ELEGIRNOS
      </div>
      <div className="flex flex-col justify-center lg:flex-row gap-4">
        {items.map((item, index) => (
          <div key={index} className="mb-8 flex-1">
            <div className="text-md lg:text-xl text-primary">{item.title}</div>
            {item.content && <div className="text-sm">{item.content}</div>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default WhyChoiseUs
