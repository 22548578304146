import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"

import ProductGrid from "../components/ProductGrid"
import WhyChoiseUs from "../components/WhyChoiseUs"

const cities = [
  "Capital Federal",
  "Rosario",
  "Chaco",
  "Santa Fe",
  "Corrientes",
  "Tucuman",
  "San Luis",
  "Mar del Plata",
  "Parana",
  "Carlos Paz",
]
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="mb-6">
        <Hero />
      </div>
      <div className="flex flex-col px-4 md:px-0 max-w-6xl gap-4 mx-auto mb-6 justify-center items-center lg:flex-row">
        <iframe
          className="max-w-xl"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/VaIDcq7DglQ?si=ZFavlsQsOPyA0vNR"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p className="text-gray-700 text-xl lg:text-2xl italic text-center max-w-lg mx-auto">
          Un equipo humano altamente capacitado, utilizando recursos
          tecnológicos de vanguardia, desarrollan productos de muy alta calidad
          y brindan un servicio de primer nivel a todos los clientes.
        </p>
      </div>

      <WhyChoiseUs />

      <div className="container mx-auto px-4 mb-8">
        <h2 className="p-6 my-4 heading text-2xl">
          Sistemas instalados en todo el país
        </h2>
        <div className="flex flex-col gap-8 md:gap-40 justify-center items-center md:flex-row">
          <ul className="list-disc">
            {cities.map((city, index) => (
              <li className="text-lg" key={index}>{city}</li>
            ))}
          </ul>
          <StaticImage
            src="../images/sistemas-instalados.png"
            alt="mapa de sistemas instalados en Argentina"
            height={450}
          />
        </div>
        <h2 className="p-6 my-4 heading text-2xl">Productos</h2>
        <ProductGrid products={data.products.nodes} path="products/" />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeProducts {
    products: allMdx(sort: { fields: frontmatter___id }) {
      nodes {
        frontmatter {
          slug
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
